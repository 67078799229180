import * as React from 'react'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useEventDateInformation} from '../../../../../../commons/hooks/dates'
import {EventImage} from './event-image'
import s from './event-info.scss'
import {EventInfoProps} from '.'

export const EventInfo = ({image, title, location, eventId}: EventInfoProps) => {
  const {isMobile} = useEnvironment()
  const {fullDate} = useEventDateInformation(eventId)

  return (
    <div className={classNames(s.event, {[s.desktopEvent]: !isMobile})} data-hook={DH.EVENT_INFO}>
      {image ? <EventImage image={image} /> : null}
      <div>
        <div className={classNames(s.name, {[s.desktopName]: !isMobile})}>{title}</div>
        <div className={s.date}>
          {fullDate}, {location}
        </div>
      </div>
    </div>
  )
}
