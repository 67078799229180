import * as React from 'react'
import {Plan as PlanViewer} from '@wix/seating-viewer'
import {useViewBox} from '../../../../hooks/viewbox'
import {Controls} from './controls'
import {Legend} from './legend'
import s from './plan.scss'
import {PlanProps} from '.'

const MIN_ZOOM = 0.2
const MAX_ZOOM = 2
const ZOOM_STEP = 0.2

export const Plan = ({plan, selectedSeat, selectedSeats, legend, onSeatClick}: PlanProps) => {
  const {viewBox, zoom, changeSvgViewBox, onPointerDown, onPointerMove, onPointerUp} = useViewBox(plan)

  return (
    <div
      className={s.container}
      onPointerDown={onPointerDown}
      onPointerUp={onPointerUp}
      onPointerLeave={onPointerUp}
      onPointerMove={onPointerMove}
    >
      <PlanViewer
        currentlyClickedSeatId={selectedSeat?.id}
        selectedSeatIds={selectedSeats.map(seat => seat.id)}
        plan={plan}
        svgViewBox={viewBox}
        onSeatClick={onSeatClick}
      />
      <Legend legend={legend} />
      <Controls
        zoomOutDisabled={zoom === MIN_ZOOM}
        zoomInDisabled={zoom === MAX_ZOOM}
        onZoomIn={() => changeSvgViewBox({deltaScale: ZOOM_STEP})}
        onZoomOut={() => changeSvgViewBox({deltaScale: -ZOOM_STEP})}
      />
    </div>
  )
}
