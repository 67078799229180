import * as React from 'react'
import s from './label-with-value.scss'
import {LabelWithValueProps} from '.'

export const LabelWithValue = ({label, value}: LabelWithValueProps) =>
  value ? (
    <div className={s.labelWithValue}>
      <div className={s.smallText}>{label}</div>
      <div>{value}</div>
    </div>
  ) : null
