import * as React from 'react'
import {Tickets} from '../tickets'
import {Plan} from '../plan'
import {Footer} from '../footer'
import {AccessibilityMode} from '../accessibility-mode'
import {SelectedTickets} from '../../../../types'
import {SelectedSeat} from '../../../../types/seating'
import s from './mobile.scss'
import {MobileProps} from '.'

export const Mobile = ({
  selectedSeats,
  selectedSeat,
  plan,
  legend,
  onRemoveClick,
  onCheckoutClick,
  onSeatClick,
  onAddClick,
  onCloseClick,
  onAddTicketsToCartClick,
}: MobileProps) => {
  const [showTickets, setShowTickets] = React.useState(false)
  const [showAccessibilityMode, setShowAccessibilityMode] = React.useState(false)

  const handleAddTicketsToCartClick = (tickets: SelectedTickets, seats: SelectedSeat[]) => {
    setShowAccessibilityMode(false)
    onAddTicketsToCartClick(tickets, seats)
  }

  return (
    <div className={s.container}>
      <Tickets
        selectedSeats={selectedSeats}
        showTickets={showTickets}
        onBackClick={() => setShowTickets(false)}
        onRemoveClick={onRemoveClick}
        onCheckoutClick={onCheckoutClick}
      />
      {showAccessibilityMode ? (
        <AccessibilityMode
          showAccessibilityMode={showAccessibilityMode}
          onBackClick={() => setShowAccessibilityMode(false)}
          onAddTicketsToCartClick={handleAddTicketsToCartClick}
        />
      ) : null}
      <Plan
        selectedSeat={selectedSeat}
        selectedSeats={selectedSeats}
        plan={plan}
        legend={legend}
        onSeatClick={onSeatClick}
      />
      <Footer
        selectedSeat={selectedSeat}
        onAddClick={onAddClick}
        onCloseClick={onCloseClick}
        onTicketsClick={() => setShowTickets(true)}
        onCheckoutClick={onCheckoutClick}
        onAccessibilityModeClick={() => setShowAccessibilityMode(true)}
      />
    </div>
  )
}
