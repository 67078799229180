import * as React from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import s from './empty-state.scss'

export const EmptyState = () => {
  const {t} = useTranslation()
  return (
    <div className={s.container}>
      <div className={s.title}>{t('seatings_tickets_emptyState_title')}</div>
      <div>{t('seatings_tickets_emptyState_description')}</div>
    </div>
  )
}
