import React from 'react'
import {saleStartedAndHasTickets} from '@wix/wix-events-commons-statics'
import {GoesOnSaleLabel} from '../goes-on-sale-label'
import {SaleEndedBadge} from '../sale-ended-badge'
import {SoldOutLabel} from '../sold-out-label'
import s from './total-column.scss'
import {TotalColumnProps} from '.'

export const TotalColumn: React.FC<TotalColumnProps> = ({total, t, ticket, saleScheduled, saleEnded}) => {
  const {limitPerCheckout, salePeriod} = ticket

  return (
    <>
      {saleStartedAndHasTickets(ticket) && (
        <div data-hook="total" role="cell" aria-label={`${t('ticketsPicker.subtotal')}: ${total}`}>
          {total}
        </div>
      )}

      {saleScheduled && (
        <GoesOnSaleLabel t={t} startDate={salePeriod.formattedSaleStartDate} className={s.goesOnSalePosition} />
      )}
      <SoldOutLabel t={t} limit={limitPerCheckout} />
      {saleEnded && !!limitPerCheckout && <SaleEndedBadge t={t} />}
    </>
  )
}
