import * as React from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {getCheckoutSummary, getPaidPlanPercentDiscount, getTaxLabel} from '@wix/wix-events-commons-statics'
import s from './inner-summary.scss'
import {InnerSummaryProps, SummaryItemProps} from '.'

export const InnerSummary = ({invoice, selectedTickets}: InnerSummaryProps) => {
  const {t} = useTranslation()
  const {subtotal, tax, addedFee, total, paidPlanDiscount} = getCheckoutSummary(invoice)

  return (
    <>
      <SummaryItem label={t('seatings_selectedTickets', {count: selectedTickets})} value={subtotal} />
      <SummaryItem
        label={t('ticketsThankYou.paidPlan', {
          discount: getPaidPlanPercentDiscount(invoice),
        })}
        value={paidPlanDiscount}
      />
      <SummaryItem label={getTaxLabel(invoice)} value={tax} />
      <SummaryItem label={t('checkout_serviceFee')} value={addedFee} />
      <SummaryItem label={t('mobile.ticketsPicker.total')} value={total} />
    </>
  )
}

const SummaryItem = ({label, value}: SummaryItemProps) =>
  value ? (
    <div className={s.summaryItem}>
      <div>{label}</div>
      <div>{value}</div>
    </div>
  ) : null
