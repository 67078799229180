import * as React from 'react'
import {DropdownOptionProps} from 'wix-ui-tpa/Dropdown'
import {getFormattedMoney} from '@wix/wix-events-commons-statics'
import {Type} from '@wix/ambassador-seating-public/types'
import s from './filters.scss'
import {StyledDropdown} from './styled-dropdown'
import {FiltersProps} from '.'

export const Filters = ({plan, tickets, onPriceChange, onLocationChange}: FiltersProps) => {
  const defaultOption = {id: 'all', value: 'All', isSelectable: true}
  const prices: DropdownOptionProps[] = tickets.map(ticket => ({
    id: ticket.price.value,
    value: getFormattedMoney(ticket.price),
    isSelectable: true,
  }))
  const rows: DropdownOptionProps[] = plan.sectors
    .flatMap(sector => sector.elements)
    .filter(element => element.type === Type.ROW)
    .map(row => ({id: String(row.id), value: `Row ${row.title}`, isSelectable: true}))

  return (
    <div className={s.container}>
      <StyledDropdown placeholder="Price: All" options={[defaultOption, ...prices]} onChange={onPriceChange} />
      <StyledDropdown
        placeholder="Location: All"
        options={[
          defaultOption,
          {id: '0', value: `Rows (${rows.length})`, isSectionTitle: true, isSelectable: false},
          ...rows,
        ]}
        onChange={onLocationChange}
      />
    </div>
  )
}
