import {DetailsPageAppProps} from '../../../app/interfaces'
import {connect} from '../../../runtime-context/context'
import {getBestPurchasedPlan, getSelectedPaidPlan} from '../../../../selectors/paid-plans'
import {AccessibilityModeRuntimeProps, AccessibilityModeOwnProps} from './interfaces'
import {AccessibilityMode as Presentation} from './accessibility-mode'

const mapRuntime = ({state}: DetailsPageAppProps): AccessibilityModeRuntimeProps => ({
  plan: state.seating.plan,
  tickets: state.tickets,
  selectedTickets: state.selectedTickets,
  event: state.event,
  paidPlan: getSelectedPaidPlan(state) || getBestPurchasedPlan(state),
})

export const AccessibilityMode = connect<AccessibilityModeOwnProps, AccessibilityModeRuntimeProps>(mapRuntime)(
  Presentation,
)
export * from './interfaces'
