import * as React from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {Button} from 'wix-ui-tpa/Button'
import {getPreliminaryInvoice, getTaxConfig} from '@wix/wix-events-commons-statics'
import {DropdownOptionProps} from 'wix-ui-tpa/Dropdown'
import {SlideInModal} from '../../slide-in-modal'
import {TicketsListContainer} from '../tickets-list-container'
import {Ticket} from '../ticket'
import {getSeatWithTicketInfo, getTicketDefinitionByExternalId} from '../../../../selectors/seating'
import {SummaryContainer} from '../summary-container'
import {InnerSummary} from '../inner-summary'
import {SelectedTickets} from '../../../../types'
import {SelectedSeat} from '../../../../types/seating'
import {Header} from './header'
import {Filters} from './filters'
import s from './accessibility-mode.scss'
import {AccessibilityModeProps} from '.'

export const AccessibilityMode = ({
  showAccessibilityMode,
  onBackClick,
  tickets,
  plan,
  event,
  paidPlan,
  onAddTicketsToCartClick,
  selectedTickets: currentlySelectedTickets,
}: AccessibilityModeProps) => {
  const {t} = useTranslation()
  const [selectedPrice, setSelectedPrice] = React.useState(null)
  const [selectedSeats, setSelectedSeats] = React.useState<SelectedSeat[]>([])
  const [selectedLocation, setSelectedLocation] = React.useState<DropdownOptionProps>(null)
  const currentlySelectedSeatIds = Object.values(currentlySelectedTickets).flatMap(({seatIds}) => seatIds)
  const filteredCategories = selectedPrice
    ? plan.categories.filter(category => {
        const ticketDefinition = getTicketDefinitionByExternalId(tickets, category.externalId)
        return selectedPrice.id === ticketDefinition.price.value
      })
    : plan.categories
  const seats = filteredCategories.flatMap(category => {
    const availableSeats = category.places.filter(
      place => !place.occupied && !currentlySelectedSeatIds.includes(place.id),
    )
    const filteredSeats =
      selectedLocation || selectedLocation
        ? availableSeats.filter(place => selectedLocation && place.id.split('-')[1] === selectedLocation.id)
        : availableSeats

    return filteredSeats.map(place => ({...place, categoryId: category.id, color: category.config.color}))
  })
  const seatsWithTicketInfo: SelectedSeat[] = seats.map(seat =>
    getSeatWithTicketInfo({plan, tickets, event, seat, categoryId: seat.categoryId, t}),
  )
  const selectedTickets: SelectedTickets = selectedSeats.reduce((acc, seat) => {
    if (acc[seat.ticketId]) {
      acc[seat.ticketId].quantity = acc[seat.ticketId].quantity + 1
      acc[seat.ticketId].seatIds = [...acc[seat.ticketId].seatIds, seat.id]
    } else {
      acc[seat.ticketId] = {quantity: 1, seatIds: [seat.id]}
    }
    return acc
  }, {} as SelectedTickets)
  const invoice = getPreliminaryInvoice(tickets, selectedTickets, getTaxConfig(event), paidPlan)

  return (
    <SlideInModal show={showAccessibilityMode} onBackClick={onBackClick}>
      <Header />
      <Filters
        plan={plan}
        tickets={tickets}
        onPriceChange={selectedOption => setSelectedPrice(selectedOption.id === 'all' ? null : selectedOption)}
        onLocationChange={selectedOption => setSelectedLocation(selectedOption.id === 'all' ? null : selectedOption)}
      />
      <div className={s.seatsInfo}>Total Seats: {seats.length}</div>
      <TicketsListContainer>
        {seatsWithTicketInfo.map((seat, index) => {
          const selected = selectedSeats.some(selectedSeat => selectedSeat.id === seat.id)

          return (
            <Ticket
              key={seat.id}
              firstTicket={index === 0}
              lastTicket={index === seatsWithTicketInfo.length - 1}
              {...seat}
              onClick={clickedSeat =>
                selected
                  ? setSelectedSeats(prevSelectedSeats =>
                      prevSelectedSeats.filter(selectedSeat => selectedSeat.id !== seat.id),
                    )
                  : setSelectedSeats(prevSelectedSeats => [...prevSelectedSeats, clickedSeat])
              }
              withCheckbox
              selected={selected}
            />
          )
        })}
      </TicketsListContainer>
      <SummaryContainer>
        {selectedSeats.length ? <InnerSummary invoice={invoice} selectedTickets={selectedSeats.length} /> : null}
        <Button
          className={s.button}
          disabled={!selectedSeats.length}
          onClick={() => onAddTicketsToCartClick(selectedTickets, selectedSeats)}
        >
          Add tickets to cart
        </Button>
      </SummaryContainer>
    </SlideInModal>
  )
}
