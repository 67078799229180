import classNames from 'classnames'
import React from 'react'
import {shouldShowSaleEndsLabel} from '@wix/wix-events-commons-statics'
import c from '../../../../classnames.scss'
import {Price} from '../price'
import {SaleEndsLabel} from '../sale-ends-label'
import {TicketDescription} from '../ticket-description'
import s from './ticket-name-column.scss'
import {TicketNameColumnProps} from '.'

export const TicketNameColumn: React.FC<TicketNameColumnProps> = ({t, ticket, saleStarted}) => {
  const {id, name, description} = ticket

  return (
    <>
      <div
        className={classNames(c.evTicketNameColor, c.evTicketNameFont, s.ticket)}
        data-hook="name"
        role="cell"
        aria-label={`${t('ticketsThankYou.ticketName')}: ${name}`}
      >
        {name}
      </div>
      <Price className={classNames(s.ticketNamePrice, c.evTextFont, c.evTextColor)} ticket={ticket} />
      {shouldShowSaleEndsLabel(ticket) && <SaleEndsLabel t={t} endDate={ticket.salePeriod.formattedSaleEndDate} />}
      {description && (
        <div className={s.description}>
          <TicketDescription ticketDefId={id} description={description} />
        </div>
      )}
    </>
  )
}
