import {withTranslation} from '@wix/yoshi-flow-editor'
import {DetailsPageAppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/context'
import {SeatingPlanRuntimeProps} from './interfaces'
import {SeatingPlan as Presentation} from './seating-plan'

const mapRuntime = ({
  state: {
    seating: {plan, loading, selectedSeats},
    tickets,
    selectedTickets,
    event,
  },
  actions: {selectTicket, selectTickets, unselectTicket, checkout, selectSeat, selectSeats, unselectSeat},
}: DetailsPageAppProps): SeatingPlanRuntimeProps => ({
  selectedSeats,
  plan,
  tickets,
  selectedTickets,
  selectTicket,
  selectTickets,
  unselectTicket,
  checkout,
  loading,
  event,
  selectSeat,
  selectSeats,
  unselectSeat,
})

export const SeatingPlanModal = connect<{}, SeatingPlanRuntimeProps>(mapRuntime)(withTranslation()(Presentation))
export * from './interfaces'
