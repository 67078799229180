import * as React from 'react'
import classNames from 'classnames'
import ArrowLeftSmall from 'wix-ui-icons-common/ArrowLeftSmall'
import {useTranslation, useEnvironment} from '@wix/yoshi-flow-editor'
import s from './slide-in-modal.scss'
import {SlideInModalProps} from '.'

export const SlideInModal: React.FC<SlideInModalProps> = ({children, show, onBackClick}) => {
  const {isMobile} = useEnvironment()
  const {t} = useTranslation()

  return (
    <div
      className={classNames(s.container, {
        [s.show]: show,
        [s.hide]: !show,
        [s.desktopContainer]: !isMobile,
      })}
    >
      {onBackClick ? (
        <div className={s.header}>
          <div className={s.backButton} onClick={onBackClick}>
            <ArrowLeftSmall /> <div>{t('seatings_backToMap')}</div>
          </div>
        </div>
      ) : null}
      {children}
    </div>
  )
}
