import * as React from 'react'
import {getImageStyle} from '../../../../../../../commons/utils/image'
import {EventImageProps} from '.'

export const EventImage = ({image}: EventImageProps) => {
  const {src} = getImageStyle({
    image,
    container: {width: 71, height: 43},
    opacity: 1,
    position: 5,
    superUpscaled: true,
  })

  return <img src={src} width="71px" height="43px" alt="" />
}
