import * as React from 'react'
import {Tickets} from '../../tickets'
import {AccessibilityMode} from '../../accessibility-mode'
import {SelectedTickets} from '../../../../../types'
import {SelectedSeat} from '../../../../../types/seating'
import s from './sidebar.scss'
import {SidebarProps} from '.'

export const Sidebar = ({selectedSeats, onRemoveClick, onCheckoutClick, onAddTicketsToCartClick}: SidebarProps) => {
  const [showAccessibilityMode, setShowAccessibilityMode] = React.useState(false)

  const handleAddTicketsToCartClick = (selectedTickets: SelectedTickets, selectedSeats: SelectedSeat[]) => {
    setShowAccessibilityMode(false)
    onAddTicketsToCartClick(selectedTickets, selectedSeats)
  }

  return (
    <div className={s.sidebar}>
      {showAccessibilityMode ? (
        <AccessibilityMode
          showAccessibilityMode={showAccessibilityMode}
          onBackClick={() => setShowAccessibilityMode(false)}
          onAddTicketsToCartClick={handleAddTicketsToCartClick}
        />
      ) : null}
      {!showAccessibilityMode ? (
        <Tickets
          selectedSeats={selectedSeats}
          showTickets={true}
          onRemoveClick={onRemoveClick}
          onCheckoutClick={onCheckoutClick}
          onAccessibilityModeClick={() => setShowAccessibilityMode(true)}
        />
      ) : null}
    </div>
  )
}
