import * as React from 'react'
import s from './item.scss'
import {LegendItemProps} from '.'

export const LegendItem = ({color, label}: LegendItemProps) => (
  <li className={s.legendItem}>
    <div
      className={s.color}
      style={{backgroundColor: color, border: color === '#fff' ? '1px solid black' : undefined}}
    />
    {label}
  </li>
)
