import * as React from 'react'
import ChevronUpSmall from 'wix-ui-icons-common/on-stage/ChevronUpSmall'
import ChevronDownSmall from 'wix-ui-icons-common/on-stage/ChevronDownSmall'
import classNames from 'classnames'
import {useTranslation, useEnvironment} from '@wix/yoshi-flow-editor'
import s from './legend.scss'
import {LegendItem} from './item'
import {LegendProps} from '.'

export const Legend = ({legend}: LegendProps) => {
  const {isMobile} = useEnvironment()
  const {t} = useTranslation()
  const [expanded, setExpanded] = React.useState(false)

  return (
    <div
      className={classNames(s.container, {
        [s.desktopContainer]: !isMobile,
      })}
      onClick={() => setExpanded(!expanded)}
    >
      <div className={s.label}>
        {t('seatings_legend_title')} {expanded ? <ChevronDownSmall /> : <ChevronUpSmall />}
      </div>
      <ul className={classNames(s.list, {[s.listExpanded]: expanded})}>
        {legend.map(({color, price}) => (
          <LegendItem color={color} label={price} />
        ))}
        <LegendItem color="#fff" label={t('seatings_legend_unavailable')} />
      </ul>
    </div>
  )
}
