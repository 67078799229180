import * as React from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {InnerSummary} from '../inner-summary'
import s from './summary.scss'
import {Buttons} from './buttons'
import {SummaryProps} from '.'

export const Summary = ({invoice, selectedTickets, onTicketsClick, onCheckoutClick}: SummaryProps) => {
  const {t} = useTranslation()

  return (
    <>
      <div className={s.container}>
        {selectedTickets ? (
          <InnerSummary invoice={invoice} selectedTickets={selectedTickets} />
        ) : (
          t('seatings_selectTickets')
        )}
      </div>
      {onTicketsClick || onCheckoutClick ? (
        <Buttons selectedTickets={selectedTickets} onTicketsClick={onTicketsClick} onCheckoutClick={onCheckoutClick} />
      ) : null}
    </>
  )
}
