import type {ICashierPaymentsApi, ICashierPaymentsProps} from '@wix/cashier-payments-widget'
import {CashierPaymentsWidgetLazy} from '@wix/cashier-payments-widget/lazy'
import {EVENTS_APP_ID, focusElement, hookToAttributeSelector, parseQueryParams} from '@wix/wix-events-commons-statics'
import React from 'react'
import {ReservationState} from '../../../../../../commons/enums'
import {PaymentWidgetProps, PaymentWidgetState} from './interfaces'

const PAYMENT_WIDGET_ID = 'payment-widget'

export class PaymentWidget extends React.Component<PaymentWidgetProps, PaymentWidgetState> {
  state = {
    selectedPaymentMethodId: null as string,
  }

  cashierApi: ICashierPaymentsApi

  componentDidMount() {
    this.focus()
  }

  focus = () => focusElement({selector: `#${PAYMENT_WIDGET_ID}`})

  pay = () => this.cashierApi.pay()

  validate = () => this.cashierApi.validate().then(({isValid}) => isValid)

  collapse = () => this.cashierApi.collapse()

  expand = () => this.cashierApi.expand()

  onPaymentMethodChanged = (selectedPaymentMethodId: string) => {
    const {openCantCompletePaymentModal, isPreview} = this.props
    this.setState({selectedPaymentMethodId})
    if (isPreview) {
      openCantCompletePaymentModal()
    }
  }

  onPaymentResult = (result: any) => {
    if (result.status === 'OK') {
      const {request} = parseQueryParams(this.props.order.ticketsPdf)
      this.props.navigateToOrder(this.props.reservationId, ReservationState.SUCCESS, {token: request})
    } else {
      focusElement({selector: hookToAttributeSelector('pw-payment-error')})
    }
  }

  onApiInit = api => {
    this.cashierApi = api
    this.props.updateLayout()
  }

  getCashierProps = (): ICashierPaymentsProps => {
    const {
      snapshotId,
      locale,
      isMobile,
      externalSubmitButton,
      isSite,
      visitorId,
      instanceId,
      msid,
      primaryLanguage,
      siteOwnerId,
      isPreview,
      instance,
      siteStyles,
      isSignedInUser,
      lang,
      order,
    } = this.props
    return {
      configuration: {
        appId: EVENTS_APP_ID as any,
        msid,
        appInstanceId: instanceId,
        snapshotId,
        locale,
        visitorId,
        isPrimaryLanguage: primaryLanguage,
        siteOwnerId,
        lang,
        viewMode: isPreview ? 'Preview' : 'Site',
        appInstance: instance,
        isSignedInUser,
        amount: order?.totalPrice?.amount,
        currency: order?.totalPrice?.currency,
      },
      siteStyles,
      theme: 'accordion',
      deviceType: isMobile ? 'mobile' : 'desktop',
      externalSubmitButton: externalSubmitButton || !isSite,
      paymentMethodChanged: this.onPaymentMethodChanged,
      paymentResult: this.onPaymentResult,
      onApiInit: this.onApiInit,
      walletPaymentInNewTab: false,
      shouldApplySiteStyles: true,
      allowManualPayment: true,
      isSaveCCEnabled: true,
    }
  }

  render() {
    return (
      <div id={PAYMENT_WIDGET_ID} className="a11yOutline">
        <CashierPaymentsWidgetLazy {...this.getCashierProps()} />
      </div>
    )
  }
}
