import classNames from 'classnames'
import React from 'react'
import {Tooltip} from 'wix-ui-tpa/Tooltip'
import {
  saleEndedAndHasTickets,
  saleStartedAndHasTickets,
  shouldShowSaleEndsLabel,
} from '@wix/wix-events-commons-statics'
import {Badge} from '../../../badge'
import c from '../../../classnames.scss'
import {QuantityPicker} from '../quantity-picker'
import s from './mobile-ticket.scss'
import {Price} from './price'
import {TicketDescription} from './ticket-description'
import {SaleEndsLabel} from './sale-ends-label'
import {GoesOnSaleLabel} from './goes-on-sale-label'
import {SoldOutProps, TicketProps, SaleEndedProps} from '.'

export const Ticket: React.FC<TicketProps> = ({
  ticket,
  selectedQuantity,
  availableTicketsQuantity,
  registrationClosed,
  onChange,
  t,
  isMobile,
  maxTicketsPerReservation,
  saleScheduled,
}) => {
  const {id, limitPerCheckout, name, description} = ticket
  const showTooltip = selectedQuantity > 0 && availableTicketsQuantity === 0

  return (
    <div key={id} className={classNames(s.ticket, c.evTextColor, c.evTextFont)} data-hook="ticket">
      {!limitPerCheckout ? <SoldOut t={t} /> : null}
      {saleEndedAndHasTickets(ticket) && <SaleEnded t={t} />}
      <div
        className={classNames(s.name, c.evTicketNameColor, c.evTicketNameFont)}
        data-hook="ticket-name"
        aria-label={`${t('ticketsThankYou.ticketName')}: ${name}`}
      >
        {name}
      </div>
      <Price className={s.price} ticket={ticket} />
      {shouldShowSaleEndsLabel(ticket) && (
        <SaleEndsLabel endDate={ticket.salePeriod.formattedSaleEndDate} t={t} className={s.saleEndsContainer} />
      )}
      {saleStartedAndHasTickets(ticket) && (
        <Tooltip
          className={s.tooltip}
          disabled={!showTooltip}
          shown={showTooltip}
          moveBy={{x: 10, y: 0}}
          minWidth={127}
          placement="right"
          content={
            maxTicketsPerReservation === 1
              ? t('ticketLimitPerReservationReached_singular')
              : t('ticketLimitPerReservationReached', {count: maxTicketsPerReservation})
          }
        >
          <div className={s.quantity}>
            <QuantityPicker
              quantity={selectedQuantity}
              min={0}
              max={Math.min(availableTicketsQuantity + selectedQuantity, limitPerCheckout)}
              disabled={!limitPerCheckout || registrationClosed}
              onChange={value => onChange({ticketId: id, count: value})}
              t={t}
              isMobile={isMobile}
            />
          </div>
        </Tooltip>
      )}
      {saleScheduled && (
        <GoesOnSaleLabel t={t} startDate={ticket.salePeriod.formattedSaleStartDate} className={s.goesOnSaleContainer} />
      )}
      {description ? (
        <div className={s.description}>
          <TicketDescription ticketDefId={id} description={description} />
        </div>
      ) : null}
      <div className={classNames(c.evTicketDividerColor, s.mobileCheckoutDivider)} />
    </div>
  )
}

const SoldOut: React.FC<SoldOutProps> = ({t}) => (
  <div className={s.soldOut}>
    <Badge>{t('mobile.ticketsPicker.soldOut')}</Badge>
  </div>
)

const SaleEnded: React.FC<SaleEndedProps> = ({t}) => (
  <div className={s.saleEnded}>
    <Badge>{t('ticketsPicker.saleEndedBadge')}</Badge>
  </div>
)
