import * as React from 'react'
import {Spinner} from 'wix-ui-tpa/Spinner'
import {useTranslation} from '@wix/yoshi-flow-editor'
import s from './loader.scss'

export const Loader = () => {
  const {t} = useTranslation()

  return (
    <div className={s.container}>
      <div className={s.text}>
        <div className={s.title}>{t('seatings_loader_title')}</div>
        <div>{t('seatings_loader_subtitle')}</div>
      </div>
      <Spinner />
    </div>
  )
}
