import React from 'react'
import classNames from 'classnames'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import c from '../../../../classnames.scss'
import {GoesOnSaleProps} from '.'

export const GoesOnSaleLabel: React.FC<GoesOnSaleProps> = ({startDate, className, t}) => (
  <div
    className={classNames(c.evTextFont, c.evTextColor, className)}
    role="cell"
    aria-label={`${t('ticketsPicker.goesOnSale')}: ${startDate}`}
    data-hook={DH.TICKET_GOES_ON_SALE}
  >
    <div>{t('ticketsPicker.goesOnSale')}</div>
    <div>{startDate}</div>
  </div>
)
