import * as React from 'react'
import BulletedListSmall from 'wix-ui-icons-common/on-stage/BulletedListSmall'
import {TextButton, TEXT_BUTTON_PRIORITY} from 'wix-ui-tpa/TextButton'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import {Divider} from '../../common/divider'
import s from './accessibility-mode-button.scss'
import {AccessibilityModeButtonProps} from '.'

export const AccessibilityModeButton = ({onAccessibilityModeClick}: AccessibilityModeButtonProps) => {
  const {isMobile} = useEnvironment()

  return (
    <div className={classNames(s.accessibilityModeContainer, {[s.desktop]: !isMobile})}>
      <TextButton
        priority={TEXT_BUTTON_PRIORITY.secondary}
        prefixIcon={<BulletedListSmall />}
        onClick={onAccessibilityModeClick}
      >
        Select from list
      </TextButton>
      {isMobile ? <Divider className={s.divider} /> : null}
    </div>
  )
}
