import * as React from 'react'
import {Plan} from '../plan'
import s from './desktop.scss'
import {Sidebar} from './sidebar'
import {DesktopProps} from '.'

export const Desktop = ({
  selectedSeat,
  selectedSeats,
  plan,
  legend,
  onSeatClick,
  onRemoveClick,
  onCheckoutClick,
  onAddTicketsToCartClick,
}: DesktopProps) => (
  <div className={s.container}>
    <Sidebar
      selectedSeats={selectedSeats}
      onRemoveClick={onRemoveClick}
      onCheckoutClick={onCheckoutClick}
      onAddTicketsToCartClick={onAddTicketsToCartClick}
    />
    <div className={s.planContainer}>
      <Plan
        selectedSeat={selectedSeat}
        selectedSeats={selectedSeats}
        plan={plan}
        legend={legend}
        onSeatClick={onSeatClick}
      />
    </div>
  </div>
)
