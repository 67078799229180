import * as React from 'react'
import classNames from 'classnames'
import {TicketInfo} from '../ticket-info'
import s from './ticket.scss'
import {TicketProps} from '.'

export const Ticket = ({
  onRemoveClick,
  onClick,
  withCheckbox,
  selected,
  firstTicket,
  lastTicket,
  ...selectedSeat
}: TicketProps) => {
  const handleRemove = () => onRemoveClick(selectedSeat.id, selectedSeat.ticketId)

  const handleKeyDown: React.KeyboardEventHandler<HTMLLIElement> = event => {
    if (event.key === 'Enter') {
      onClick(selectedSeat)
    }
  }

  return (
    <li
      className={classNames(s.ticket, {[s.firstTicket]: firstTicket, [s.lastTicket]: lastTicket})}
      onClick={() => onClick(selectedSeat)}
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      <div className={s.color} style={{backgroundColor: selectedSeat.color}} />
      <TicketInfo
        {...selectedSeat}
        onRemoveClick={onRemoveClick ? handleRemove : undefined}
        withCheckbox={withCheckbox}
        selected={selected}
      />
    </li>
  )
}
