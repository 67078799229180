import * as React from 'react'
import {Button} from 'wix-ui-tpa/Button'
import {IconButton} from 'wix-ui-tpa/IconButton'
import CloseSmall from 'wix-ui-icons-common/on-stage/CloseSmall'
import DeleteSmall from 'wix-ui-icons-common/on-stage/DeleteSmall'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {Checkbox} from 'wix-ui-tpa/Checkbox'
import {LabelWithValue} from '../../../common/label-with-value'
import s from './ticket-info.scss'
import {TicketInfoProps} from '.'

export const TicketInfo = ({
  withCheckbox,
  selected,
  sector,
  seat,
  row,
  ticketName,
  ticketPrice,
  fees,
  onAddClick,
  onCloseClick,
  onRemoveClick,
}: TicketInfoProps) => {
  const {t} = useTranslation()

  return (
    <div className={s.container}>
      {withCheckbox ? <Checkbox disabled className={s.checkbox} checked={selected} onChange={undefined} /> : null}
      {onCloseClick ? <IconButton className={s.headerButton} icon={<CloseSmall />} onClick={onCloseClick} /> : null}
      {onRemoveClick ? <IconButton className={s.headerButton} icon={<DeleteSmall />} onClick={onRemoveClick} /> : null}
      <div className={s.infoContainer}>
        <LabelWithValue label={t('seatings_sector')} value={sector} />
        <LabelWithValue label={t('seatings_row')} value={row} />
        <LabelWithValue label={t('seatings_seat')} value={seat} />
      </div>
      <div>
        <div className={s.smallText}>{ticketName}</div>
        <div>{ticketPrice}</div>
        <div className={s.smallText}>{fees}</div>
      </div>
      {onAddClick ? (
        <Button onClick={onAddClick} data-hook={DH.ADD}>
          {t('seatings_add')}
        </Button>
      ) : null}
    </div>
  )
}
