import {Place, Element} from '@wix/ambassador-seating-public/types'
import {getFormattedPrice, WIX_EVENTS_TICKET_DEFINITION_FQDN} from '@wix/wix-events-commons-statics'
import React, {useState} from 'react'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {Modal} from '../modal'
import {getSelectedTicketQuantity} from '../../../selectors/selected-tickets'
import {getSeatWithTicketInfo} from '../../../selectors/seating'
import {SelectedSeat} from '../../../types/seating'
import {SelectedTickets} from '../../../types'
import {Loader} from './loader'
import {Mobile} from './mobile'
import s from './seating-plan.scss'
import {Desktop} from './desktop'
import {SeatingPlanProps, Legend} from '.'

export const SeatingPlan = ({
  plan,
  tickets,
  selectTicket,
  selectTickets,
  unselectTicket,
  selectedTickets,
  checkout,
  loading,
  event,
  selectedSeats,
  selectSeat,
  unselectSeat,
  selectSeats,
}: SeatingPlanProps) => {
  const {t} = useTranslation()
  const {isMobile} = useEnvironment()
  const [selectedSeat, setSelectedSeat] = useState<SelectedSeat>(null)
  const [legend, setLegend] = useState<Legend[]>([])

  React.useEffect(() => {
    const legendItems = plan.categories.map(({externalId, config: {color}}) => {
      const {price} = tickets.find(
        ticketDef => ticketDef.id === externalId.replace(WIX_EVENTS_TICKET_DEFINITION_FQDN, ''),
      )
      return {
        color,
        price: getFormattedPrice(Number(price.value), price.currency),
      }
    })

    setLegend(legendItems)
  }, [plan, tickets])

  React.useEffect(() => {
    if (!isMobile && selectedSeat) {
      handleAddClick()
    }
  }, [selectedSeat])

  const handleCheckout = () => checkout()

  const handleAddClick = () => {
    const ticketId = selectedSeat.ticketId

    selectSeat(selectedSeat)
    selectTicket({
      ticketId,
      count: getSelectedTicketQuantity(selectedTickets, ticketId) + 1,
      seatId: selectedSeat.id,
    })
    setSelectedSeat(null)
  }

  const handleRemove = (id: string, ticketId: string) => {
    unselectTicket({ticketId, count: getSelectedTicketQuantity(selectedTickets, ticketId) - 1, seatId: id})
    unselectSeat(id)
  }

  const onSeatClick = (seat: Place, categoryId: number, definition: Element) => {
    const {externalId} = plan.categories.find(category => category.id === categoryId)
    const ticketDefinition = tickets.find(
      ticketDef => ticketDef.id === externalId.replace(WIX_EVENTS_TICKET_DEFINITION_FQDN, ''),
    )

    if (selectedSeats.find(selected => selected.id === seat.id)) {
      return handleRemove(seat.id, ticketDefinition.id)
    }

    setSelectedSeat(getSeatWithTicketInfo({plan, event, seat, categoryId, t, tickets}))
  }

  const handleAddTicketsToCart = (pickedTickets: SelectedTickets, pickedSeats: SelectedSeat[]) => {
    selectTickets(pickedTickets)
    selectSeats(pickedSeats)
  }

  const component = isMobile ? (
    <Mobile
      selectedSeat={selectedSeat}
      selectedSeats={selectedSeats}
      plan={plan}
      legend={legend}
      onRemoveClick={handleRemove}
      onCheckoutClick={handleCheckout}
      onSeatClick={onSeatClick}
      onCloseClick={() => setSelectedSeat(null)}
      onAddClick={handleAddClick}
      onAddTicketsToCartClick={handleAddTicketsToCart}
    />
  ) : (
    <Desktop
      selectedSeat={selectedSeat}
      selectedSeats={selectedSeats}
      plan={plan}
      legend={legend}
      onSeatClick={onSeatClick}
      onRemoveClick={handleRemove}
      onCheckoutClick={handleCheckout}
      onAddTicketsToCartClick={handleAddTicketsToCart}
    />
  )

  return (
    <Modal
      className={!isMobile ? s.desktopModal : undefined}
      skin={loading ? undefined : 'grey'}
      closeButtonAsButton={!loading}
      dataHook={DH.SEATINGS_MODAL}
    >
      {loading ? <Loader /> : component}
    </Modal>
  )
}
